// style start --
// bootstrap icon style
import 'bootstrap-icons/font/bootstrap-icons.css'

// around main css
import '@dashkit/scss/theme.scss'
// -- end style

// bootstrap
import * as bootstrap from 'bootstrap'

// rails
import Rails from '@rails/ujs'
// i18n
import I18n from '@commons/i18n.js'

import { configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
// import en from '@vee-validate/i18n/dist/locale/en.json'
import locale from '@vee-validate/i18n/dist/locale/zh_TW.json'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { createPinia } from 'pinia'

try { Rails.start() } catch (e) { }
window.I18n = I18n
window.bootstrap = bootstrap

configure({
  generateMessage: localize({ en: locale })
})

createInertiaApp({
  // resolve: name => import(`@views/${name}`),
  resolve: name => {
    const viewModules = import.meta.glob('./../views/**/*.vue')
    const page = Object.keys(viewModules).find(page => page.includes(name))
    return viewModules[page]()
  },
  setup ({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(createPinia())
      .mount(el)
  }
})
